document.addEventListener('DOMContentLoaded', function () {
    const menuToggle = document.querySelector('.menu-toggle'),
          nav = document.querySelector('.nav-wrap'),
          body = document.querySelector('body');

    menuToggle.addEventListener('click', function () {
        nav.classList.toggle('nav-active');
        body.classList.toggle('noscroll');
    });


    const form = document.getElementById('integration-contact-form');

    // Check if the form exists
    if (form) {

        //format phone
        document.getElementById('phone').addEventListener('input', function (e) {
            const input = e.target;
            const value = input.value.replace(/\D/g, ''); // Remove all non-numeric characters
            const length = value.length;

            if (length < 4) {
                input.value = value;
            } else if (length < 7) {
                input.value = `(${value.slice(0, 3)}) ${value.slice(3)}`;
            } else if (length < 11) {
                input.value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6)}`;
            } else {
                input.value = `${value[0]} (${value.slice(1, 4)}) ${value.slice(4, 7)}-${value.slice(7, 11)}`;
            }
        });



        const formthanks = document.querySelector('.integrations-contact__thanks'),
              formSubmit = document.getElementById('integrations-contact__submit');
        form.addEventListener('submit', function(event) {
            event.preventDefault(); // Prevent the default form submission
            formSubmit.classList.add('loading');

            // Create an object to hold the form data
            let formData = {
                email: '',
                fullName: '',
                zipCode: '',
                phone: '',
                venue_size: '',
                needs: [],
                budget: '',
                message: ''
            };

            // Loop through the form elements and add them to the formData object
            Array.from(form.elements).forEach(element => {
                element.classList.remove('has-error');
                document.getElementById(element.name + '-error')?.remove();
                if (element.name) {
                    switch (element.name) {
                        case 'name':
                            formData.fullName = element.value;
                            break;
                        case 'zipCode':
                            formData.zipCode = parseInt(element.value, 10);
                            break;
                        case 'phone':
                            formData.phone = parseInt(element.value.replace(/[^0-9]/g, ''), 10); // Remove non-numeric characters
                            break;
                        case 'venue_size':
                            if (element.checked) {
                                formData.venue_size = element.value;
                            }
                            break;
                        case 'needs':
                            if (element.checked) {
                                formData.needs.push(element.value);
                            }
                            break;
                        default:
                            formData[element.name] = element.value;
                            break;
                    }
                }
            });

            // JSON stringify the form data
            const jsonData = JSON.stringify(formData);

            // Send the data via an AJAX POST request
            const xhr = new XMLHttpRequest();
            xhr.open('POST', '/webservices_sw/prospects/integration', true);
            xhr.setRequestHeader('Content-Type', 'application/json');

            xhr.onreadystatechange = function(state) {
                
                if (xhr.readyState === 4) {
                    if (xhr.status >= 200 && xhr.status < 300) {
                        form.style.display="none";
                        formthanks.style.display="block";
                        formthanks.scrollIntoView({ behavior: 'smooth' });
                    } else {
                        const formerror = document.querySelector('.integrations-contact__error');
                        formerror.style.display = "block";
                        formSubmit.classList.remove('loading');
                        const errors = JSON.parse(xhr.responseText);
                        Array.from(form.elements).forEach(formElement => {
                            if (
                                formElement
                                && formElement.parentElement
                                && errors[formElement.name]
                                && !document.getElementById(formElement.name + '-error')
                            ) {
                                formElement.parentElement.classList.add('has-error');
                                const errorElement = document.createElement('div');
                                errorElement.classList.add('error-message');
                                errorElement.id = formElement.name + '-error';
                                errorElement.innerText = errors[formElement.name][0];
                                formElement.parentElement.appendChild(errorElement);
                            }
                        });
                    }
                }
            };

            xhr.send(jsonData);
        });
    } else {
        
    }

    // Slider

    const sliders = document.querySelectorAll('.cs-slider');

for (let i = 0; i < sliders.length; i++) {
    const slider = sliders[i],
        frame = slider.querySelector('.cs-slider__frame'),
        items = slider.querySelectorAll('.cs-slider__item'),
        pagination = slider.querySelector('.cs-slider__pagination'),
        prev = slider.querySelector('.cs-slider__prev'),
        next = slider.querySelector('.cs-slider__next');

    function initializeSlider() {

        if (frame.scrollWidth > slider.offsetWidth) {
            pagination.innerHTML = ''; // Clear previous pagination items
            for (let j = 0; j < items.length; j++) {
                pagination.innerHTML += '<span class="cs-slider__pagination-item"></span>';
            }

            const paginationItems = pagination.querySelectorAll('.cs-slider__pagination-item');

            function updatePagination() {
                const percentageScrolled = frame.scrollLeft / (frame.scrollWidth - frame.clientWidth);
                const paginationIndex = Math.round(percentageScrolled * (items.length - 1));
                
                paginationItems.forEach((item, index) => {
                    item.classList.toggle('cs-slider__pagination-active', index === paginationIndex);
                });
            }

            function checkEdges() {
                slider.classList.toggle('cs-slider__start', frame.scrollLeft === 0);
                slider.classList.toggle('cs-slider__end', frame.scrollLeft + frame.clientWidth >= frame.scrollWidth);
            }

            function postScroll() {
                checkEdges();
                updatePagination();
            }

            next.addEventListener('click', function() {
                frame.scrollBy({ left: frame.clientWidth * 0.25, behavior: 'smooth' });
                postScroll();
            });

            prev.addEventListener('click', function() {
                frame.scrollBy({ left: -frame.clientWidth * 0.25, behavior: 'smooth' });
                postScroll();
            });

            frame.addEventListener('scroll', function() {
                postScroll();
            });

            postScroll();
        } else {
            slider.classList.add('cs-slider__noscroll');
        }
    }

    const images = slider.querySelectorAll('img');
    let imagesLoaded = 0;

    function imageLoaded() {
        imagesLoaded++;
        if (imagesLoaded === images.length) {
            initializeSlider();
        }
    }

    images.forEach(image => {
        if (image.complete) {
            imageLoaded();
        } else {
            image.addEventListener('load', imageLoaded);
            image.addEventListener('error', imageLoaded); // In case an image fails to load
        }
    });

    if (images.length === 0) {
        initializeSlider();
    }
}

// Tabs

const tabs = document.querySelectorAll(".tab");
const contents = document.querySelectorAll(".content");
if(tabs.length > 0) {
    tabs.forEach(tab => {
        tab.addEventListener("click", function () {
            tabs.forEach(t => t.classList.remove("active"));
            this.classList.add("active");
            contents.forEach(c => c.classList.remove("active"));
            document.getElementById(this.getAttribute("data-target")).classList.add("active");
        });
    });

    tabs[0].classList.add("active");
    contents[0].classList.add("active");
}

    


});


